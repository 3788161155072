
BX.ready(function(){

	let obDLMenu = document.querySelectorAll('.block-wrapper .l-mmenu-type1');

	var menu = {};

	for (let i in obDLMenu)
	{
		if (obDLMenu.hasOwnProperty(i))
		{
			let obDLMenuSubmenu = obDLMenu[i].querySelectorAll('ul');
			for (let j in obDLMenuSubmenu)
			{
				if (obDLMenuSubmenu.hasOwnProperty(j))
				{
						obDLMenuSubmenu[j].parentNode.classList.add('has-children');
				}
			}

			let blockId = obDLMenu[i].closest('.block-wrapper').getAttribute('id');

			obDLMenu[i].setAttribute('id', blockId + '_menu');

			new RS.MainMenu(obDLMenu[i].getAttribute('id'));
		}
	}
 });
